import Factory from '../di/factory';


class PageFactory extends Factory {

	constructor({container, prefix = '', suffix = 'Page'}) {
		super({container: container, prefix: prefix, suffix: suffix});
	}

}


export default PageFactory;
