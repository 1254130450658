import commonConfig from './common';

import appConfig from '../app/_config';
import bookingConfig from '../booking/_config';
import contextConfig from '../context/_config';
import pageConfig from '../page/_config';


const config = (di) => {
	commonConfig(di);

	appConfig(di);
	bookingConfig(di);
	contextConfig(di);
	pageConfig(di);

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	di
		.setValue('baseUrl', baseUrl)
		.setValue('google/mapApiUrl', bodyData.get('googleMapApiUrl', ''))
		.setValue('google/mapApiKey', bodyData.get('googleMapApiKey', ''))
		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
		.setParam('Navigation', 'navigateSameUrl', true)
	;
};


export default config;
