import GoogleMap from './google-map';
import GoogleMapApiLoader from './google-map-api-loader';
import YoutubeApiLoader from './youtube-api-loader';
import YoutubeVideoPlayerDriver from './youtube-video-player-driver';

export default (di) => {
	di
		.setType({
			type: GoogleMap, name: 'GoogleMap', parent: 'PageComponent', setters: {
				injectApiLoader: di.lazyGet('google/mapApiLoader'),
			}
		})
		.setType({type: GoogleMapApiLoader, name: 'GoogleMapApiLoader', params: {
				url: di.lazyValue('google/mapApiUrl', ''),
				apiKey:  di.lazyValue('google/mapApiKey', '')
			}
		})
		.set('google/mapApiLoader', di.lazyNew('GoogleMapApiLoader'))
		.setType({type: YoutubeApiLoader, name: 'YoutubeApiLoader'})
		.set('google/youtubeApiLoader', di.lazyNew('YoutubeApiLoader'))
		.setType({type: YoutubeVideoPlayerDriver, name: 'YoutubeVideoPlayerDriver', parent: 'VideoPlayerDriver', setters: {
				injectApiLoader: di.lazyGet('google/youtubeApiLoader')
			}
		})
	;
};
