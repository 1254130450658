import domMixin from '../dom/dom-mixin';


class PageSlots extends domMixin() {

    constructor({root = document.body}) {
		super();
		this.root = root;
        this.slots = new Map();
	}


	injectSlotFactory(factory) {
		this.factory = factory;
	}


	get(name) {
        if (this.slots.has(name)) {
            return this.slots.get(name);
		}
		const slotClass = this.factory.has(name) ? name : '';
		const slot = this.factory.newInstance(slotClass, {name: name, root: this.root});
		this.slots.set(name, slot);
		return slot;
	}

}


export default PageSlots;
