import SlideLoader from './slide-loader';


class NextSlideLoader extends SlideLoader {

    load(slideshow) {
		this.slideshow = slideshow;
		this.slidesCount = slideshow.getSlidesCount();

		if (this.slidesCount) {
			const currentSlide = slideshow.getCurrentSlide();
			if (currentSlide) {
				currentSlide.load().then(() => {
					slideshow.setInteractive();
					slideshow.closeAsyncEvent('interactive');
					this.loadSiblingSlide(currentSlide, 1).then(() => {
						if (this.slidesCount > 2) {
							this.loadSiblingSlide(currentSlide, -1);
						}
					});
					return Promise.resolve(slideshow);
				});
			} else {
				slideshow.setInteractive();
				slideshow.closeAsyncEvent('interactive');
			}
			this.navigateListener = this.events.on(slideshow.getElement(), 'slideshow:beforeswitch', this.onBeforeSwitch.bind(this));
		} else {
			this.navigateListener = null;
		}
		return Promise.resolve(slideshow);
	}


	onBeforeSwitch(event) {
		const newSlide = event.detail.newSlide;
		newSlide.load().then(() => this.loadSiblingSlide(newSlide, event.detail.direction));
	}


	getSiblingSlide(slide, offset) {
		if (!offset) {
			offset = 1;
		}
		const index = slide.getIndex();
		if (this.slidesCount > 1) {
			const siblingIndex = (index + this.slidesCount + offset) % this.slidesCount;
			if (siblingIndex !== index) {
				return this.slideshow.getSlide(siblingIndex);
			}
		}
		return null;
	}


	loadSiblingSlide(slide, offset) {
		const siblingSlide = this.getSiblingSlide(slide, offset);
		if (siblingSlide) {
			return siblingSlide.load();
		}
		return Promise.resolve();
	}


	destroy() {
		super.destroy();
		if (this.navigateListener) {
			this.navigateListener.destroy();
		}
	}

}

export default NextSlideLoader;
