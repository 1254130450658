import PageComponent from '../component/page-component';
import getPointerPosition from '../utils/get-pointer-position';

/**
 * Prevent a click on a link if the pointer is being dragged a certain distance from the starting point
 * or if the time passed between the first and the end touch is bigger than a certain amount
 */
class GestureAwareLink extends PageComponent {

	constructor({
		root,
		element,
		maxTimeInterval = 400, // msec.
		maxDistance = 20 // px
	}) {
		super({root: root, element: element});
		this.maxTimeInterval = maxTimeInterval;
		this.maxDistance = maxDistance;

		this.startingTime = 0;
		this.startingPosition = {};
	}


	prepare() {
		this.listeners.drag = this.events.on(this.element, 'dragstart', this.onDragStart.bind(this));
		this.listeners.start = this.events.on(this.element, 'mousedown touchstart', this.onStartInteraction.bind(this));
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}


	onDragStart(event) {
		event.preventDefault();
	}


	onStartInteraction(event) {
		this.startingTime = performance.now();
		this.startingPosition = getPointerPosition(event);
	}


	onClick(event) {
		const endingTime = performance.now();
		const finalPosition = getPointerPosition(event);
		if (endingTime - this.startingTime > this.maxTimeInterval || this.getDistance(this.startingPosition, finalPosition) > this.maxDistance) {
			event.preventDefault();
		}
	}


	getDistance(firstPoint, secondPoint) {
		return Math.max(Math.abs(firstPoint.x - secondPoint.x), Math.abs(firstPoint.y - secondPoint.y));
	}

}

export default GestureAwareLink;
