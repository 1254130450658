import {toSeconds, toMinutesSeconds} from '../utils/times';
import domMixin from '../dom/dom-mixin';

/**
 * VideoPlayerDriver
 *
 * Base class providing a few shared features among all the different drivers.
 * Each driver subclass needs to implement all the functions defined by the VideoPlayer
 *
 * @class
 */
class VideoPlayerDriver extends domMixin() {

	/**
	 *Creates an instance of VideoPlayerDriver.

	 * @param {VideoPlayer} controller: the VideoPlayer that wraps this driver
	 * @param {Element} element: the node element to be passed to the API function to attach the player markup
	 * @param {String} url: the video URL
	 * @param {Object} [params] additional player parameters
	 */
	constructor({controller, element, url, params = {}} = {}) {
		super();
		this.controller = controller;
		this.element = element;
		this.url = url;
		this.params = params;
		this.promise = null;
		this.player = null;

		// providing utilities to subclasses
		this.toSeconds = toSeconds;
		this.toMinutesSeconds = toMinutesSeconds;
	}


	injectApiLoader(apiLoader) {
		this.apiLoader = apiLoader;
	}


	// to be overriden
	destroy() {}


	/**
	 * External Video providers usually require a API library to be loaded the first time
	 * We do not load this external library until the first video is requested to load
	 *
	 * @returns {Promise}: resolved with the Driver itself
	 */
	load() {
		if (this.promise === null) {
			this.promise = new Promise((resolve) => {
				this.apiLoader.load().then((api) => this.createInternalPlayer(api)).then(() => {
					resolve(this);
				});
			});
		}
		return this.promise;
	}


	/**
	 * Create the actual video player object, to be overriden in subclasses
	 *
	 * @param {Object} api: reference to the api object used to create video players
	 * @returns {Promise} the procedure is async
	 */
	createInternalPlayer(api) {
		return Promise.resolve();
	}


	getInternalPlayer() {
		return this.player;
	}


	/**
	 * Utility method used to remap and manipulate some player parameters before istantiating the player object
	 *
	 * @param {Object} params: source parameters as provided by the wrapping VideoPlayer object
	 * @param {Object} map: contains an entry for each param that need some kind of processing / remapping
	 * @returns {Object} the redefined/remapped parameters
	 */
	remapParams(params, map) {
		let newParams = {};
		for (const name in params) {
			if (params.hasOwnProperty(name)) {
				const value = params[name];
				if (name in map) {
					// the remap can be made with a callback that returns the params obj modified
					// or simply from a key to a different key (map[name] is expected to be a string)
					if (map[name] instanceof Function) {
						newParams = map[name](newParams, name, value);
					} else {
						newParams[map[name]] = value;
					}
				} else {
					// if there are no matching entries in the map, the params is kept as is
					newParams[name] = value;
				}
			}
		}
		return newParams;
	}

}


export default VideoPlayerDriver;
