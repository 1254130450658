const localeMixin = (Base = null) => class extends Base {

	injectLocaleText(localeText) {
		this.locale = localeText;
	}


	getText(id, params = {}) {
		return this.locale.get(id, params);
	}


    translateText(lang, id, params = {}) {
		return this.locale.translate(lang, id, params);
    }

};


export default localeMixin;
