import 'dom4';
import transitionEnd from './transition-end';
import {ucFirst} from '../utils/string';
import {isString} from '../utils/types';


class ThreeStateTransition {

	constructor({classListFactory, element, animatedElement = null}) {
		this.classListFactory = classListFactory;
		this.element = element;
		this.animatedElement = animatedElement === null ? element : animatedElement;
	}


	add(name) {
		return this.applyTransition(name, true);
	}

	remove(name) {
		return this.applyTransition(name, false);
	}


	applyTransition(name, add) {
		return new Promise((resolve) => {
			let beforeName;
			let afterName;
			let duringName;
			if (isString(name)) {
				afterName = name;
				beforeName = 'before' + ucFirst(afterName);
				duringName = 'during' + ucFirst(afterName);
			} else {
				beforeName = name.before;
				afterName = name.after;
				duringName = name.during;
			}
			if (!add) {
				const swap = afterName;
				afterName = beforeName;
				beforeName = swap;
			}

			const classList = this.classListFactory({element: this.element});
			requestAnimationFrame(() => {
				classList.toggle(beforeName, add);
				requestAnimationFrame(() => {
					classList.toggle(duringName, add);
					transitionEnd(this.animatedElement).then(() => {
						requestAnimationFrame(() => classList.toggle(afterName));
						resolve(this.element);
					});
				});
			});
		});
	}

}


export default ThreeStateTransition;
