export default {
	pageAttribute: 'page',
	slotAttribute: 'pageSlot',
	sectionSlotAttribute: 'sectionSlot',
	pageUrlAttribute: 'pageUrl',
	pageTitleAttribute: 'pageTitle',
	defaultPageName: '',
	defaultSlotName: 'default',
	defaultLoaderName: 'serverRendered',
	defaultTransitionName: ''
};
