import templateMixin from '../template/template-mixin';
import localeMixin from '../locale/locale-mixin';
import apiMixin from '../api/api-mixin';
import PageLoader from './page-loader';


class ClientRenderedPageLoader extends apiMixin(localeMixin(templateMixin(PageLoader))) {

	// to be overriden
	requestPage(request) {
	}

}


export default ClientRenderedPageLoader;
