// needed to interact with vimeo api
/*eslint-disable piggyback/no-restricted-global-extend*/
import {waitCondition} from '../utils/wait';


class VimeoApiLoader {

    constructor({url = 'https://player.vimeo.com/api/player.js'} = {}) {
        this.url = url;
		this.script = null;
		this.promise = null;
        this.vimeo = null;
	}


    load() {
		if (!this.url.length) {
			throw Error('Vimeo API url is empty');
		}
		if (this.promise === null) {
			this.promise = new Promise((resolve) => {
				this.promiseResolveTrigger = resolve;
				this.script = document.createElement('script');
				this.script.async = true;
				this.script.defer = true;
				this.script.setAttribute('src', this.url);
				document.body.appendChild(this.script);
				waitCondition(() => 'Vimeo' in window && 'Player' in window.Vimeo).then(() => {
					this.vimeo = window.Vimeo;
					resolve(this.vimeo);
				});
			});
		}
		return this.promise;
	}

}


export default VimeoApiLoader;
