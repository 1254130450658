import BookingForm from './booking-form';
import BookingService from './booking-service';


export default (di) => {
	di
		.setType({type: BookingForm, name: 'BookingForm', parent: 'PageComponent', setters: {
				injectBookingService: di.lazyGet('booking/service')
			}
		})
		.setType({type: BookingService, name: 'BookingService', mixins: ['navigationMixin']})
		.set('booking/service', di.lazyNew('BookingService'))	// replace the class with a specific service
	;
};
