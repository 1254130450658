import PageComponent from '../component/page-component';

const POSITION_CENTER = 'center';


class GoogleMap extends PageComponent {

	constructor({
		root,
		element,
		mapConfigAttribute = 'mapConfig',
		markersAttribute = 'markers',
		autoload = true,
		loadedClass = 'loaded'
	}) {
		super({root: root, element: element});
		this.mapConfigAttribute = mapConfigAttribute;
		this.markersAttribute = markersAttribute;
		this.defaults.autoload = autoload;

		this.markers = [];
		this.infoWindows = [];
		this.loadedClass = loadedClass;
		this.apiLoader = null;
		this.google = null;
        this.map = null;
		this.loading = false;
	}


	injectApiLoader(apiLoader) {
		this.apiLoader = apiLoader;
	}


    prepare() {
		this.openAsyncEvent('load');
		this.mapConfig = this.dataAttr().get(this.mapConfigAttribute, {});
		if (this.dataAttr().get('autoload') === true) {
			this.load();
		}
	}


    clear() {
		this.google = null;
		this.map = null;
    }


	load() {
		if (!this.loading) {
			this.loading = true;
			this.apiLoader.load().then((google) => {
				this.google = google;
			}).then(() => {
				this.map = new this.google.maps.Map(this.element, this.mapConfig);
				return new Promise((resolve) => {
					this.google.maps.event.addListenerOnce(this.map, 'idle', () => resolve());
				});
			}).then(() => {
				this.classList().add(this.loadedClass);
				this.closeAsyncEvent('load');
				this.events.trigger(this.element, 'map:load', {component: this});
				const markers = this.dataAttr().get(this.markersAttribute);
				this.addMarkers(markers);
			});
		}
		return this.on('load');
	}


	getMap() {
		return this.map;
	}


	// it accept also a single param as an object like {lat: ..., lng: ....}
	getLatLng(lat, lng) {
		if ('lat' in lat && 'lng' in lat) {
			lng = lat.lng;
			lat = lat.lat;
		}
	    return new this.google.maps.LatLng(lat, lng);
	}


	getMarkers() {
		return this.markers;
	}


	getInfoWindows() {
		return this.infoWindows;
	}


	addMarkers(markers) {
		for (const entry of markers) {
			if ('position' in entry && entry.position === POSITION_CENTER && POSITION_CENTER in this.mapConfig) {
				entry.position = this.mapConfig[POSITION_CENTER];
			}

			if ('lat' in entry.position && 'lng' in entry.position) {
				entry.position = this.getLatLng(entry.position);
			}

			if ('dropAnimation' in entry) {
				if (entry.dropAnimation) {
					entry.animation = this.google.maps.Animation.DROP;
				}
				delete entry.dropAnimation;
			}
			const popupContent = ('popupContent' in entry && entry.popupContent.length > 0 ? entry.popupContent : '');
			delete entry.popupContent;
			entry.map = this.map;
			if ('icon' in entry) {
				entry.icon.size = new this.google.maps.Size(...entry.icon.size);
				entry.icon.origin = new this.google.maps.Point(...entry.icon.origin);
				entry.icon.anchor = new this.google.maps.Point(...entry.icon.anchor);
				// {
				// 	url: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
				// 	// This marker is 20 pixels wide by 32 pixels high.
				// 	size: new google.maps.Size(20, 32),
				// 	// The origin for this image is (0, 0).
				// 	origin: new google.maps.Point(0, 0),
				// 	// The anchor for this image is the base of the flagpole at (0, 32).
				// 	anchor: new google.maps.Point(0, 32)
				//   }
			}

			const marker = new this.google.maps.Marker(entry);
			this.markers.push(marker);

			if (popupContent.length > 0) {
				const infoWindow = new this.google.maps.InfoWindow({
					content: popupContent
				});
				marker.addListener('click', () => {
					infoWindow.open(this.map, marker);
				});
				this.infoWindows.push(infoWindow);
			}
		}
	}
    // initMap() {
    //     this.map = new this.google.maps.Map(this.element, this.config);
    //     this.google.maps.event.addListener(this.map, 'bounds_changed', this.onMapChange.bind(this));
    //     this.google.maps.event.addListener(this.map, 'center_changed', this.onMapChange.bind(this));
    //     this.google.maps.event.addListener(this.map, 'resize', this.onMapChange.bind(this));
    //     this.google.maps.event.addListener(this.map, 'idle', this.onMapIdle.bind(this));
    // }


    // // http://stackoverflow.com/questions/1538681/how-to-call-fromlatlngtodivpixel-in-google-maps-api-v3
    // latLngToPoint(position) {
    //     if (!(position instanceof this.google.maps.LatLng)) {
    //         position = this.getLatLng(position.lat, position.lng);
    //     }

    //     const scale = Math.pow(2, this.map.getZoom());
    //     const bounds = this.map.getBounds();

    //     const nw = this.projection.fromLatLngToPoint(this.getLatLng(
    //         bounds.getNorthEast().lat(),
    //         bounds.getSouthWest().lng()
    //     ));
    //     const point = this.projection.fromLatLngToPoint(position);

    //     return new this.google.maps.Point(
    //         Math.floor((point.x - nw.x) * scale),
    //         Math.floor((point.y - nw.y) * scale)
    //     );
    // }


    // // http://stackoverflow.com/questions/1538681/how-to-call-fromlatlngtodivpixel-in-google-maps-api-v3
    // pointToLatLng(position) {
    //     const scale = Math.pow(2, this.map.getZoom());
    //     const bounds = this.map.getBounds();

    //     const nw = this.projection.fromLatLngToPoint(new this.google.maps.LatLng(
    //         bounds.getNorthEast().lat(),
    //         bounds.getSouthWest().lng()
    //     ));
    //     const point = new this.google.maps.Point();

    //     point.x = position.x / scale + nw.x;
    //     point.y = position.y / scale + nw.y;

    //     return this.projection.fromPointToLatLng(point);
    // }


}

export default GoogleMap;
