import Factory from '../di/factory';


class PageSlotFactory extends Factory {

	constructor({container, prefix = '', suffix = 'PageSlot'}) {
		super({container: container, prefix: prefix, suffix: suffix});
    }

}


export default PageSlotFactory;
