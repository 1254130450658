/**
 * Converts a time value provided in different formats to seconds
 *
 * @param {String|Number} time: the input value. Supported formats:
 * 130 - seconds, no conversion needed
 * 2:10 - minutes and seconds
 * 2m10s or 2m or 10s - minutes and seconds, commonly used by Video Providers
 * @returns {Int} return the number of seconds
 */
export function	toSeconds(time) {
	time = String(time);
	if (!time.length) {
		return 0;
	}
	if (time.indexOf(':') > 0) {
		const [minutes, seconds] = time.split(':');
		time = parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
	} else {
		const parts = time.match(/^(?:([0-9]+)m)?(?:([0-9]+)s)?$/);
		if (parts && parts.length) {
			time =
				(parts[1] !== undefined ? parseInt(parts[1], 10) : 0) * 60 +
				(parts[2] !== undefined ? parseInt(parts[2], 10) : 0)
			;
		} else {
			time = parseInt(time, 10);
		}
	}
	return time;
}

/**
 * Converts a time value provided in different format to the format usually preferred by Video Providers
 *
 * @param {String|Number} time: the input value. Supported formats:
 * 130 - seconds
 * 2:10 - minutes and seconds
 * 2m10s or 2m or 10s - minutes and seconds, commonly used by Video Providers
 * @returns {String} time value expressed in the form 2m10s
 */
export function toMinutesSeconds(time) {
	time = String(time);
	if (!time.length) {
		return '0s';
	}
	if (time.indexOf(':') > 0) {
		return time.split(':').join('m') + 's';
	}
	if (time.match(/^(?:([0-9]+)m)?(?:([0-9]+)s)?$/)) {
		return time;
	}
	time = parseInt(time, 10);
	const minutes = Math.floor(time / 60);
	const seconds = time - (minutes * 60);
	return `${minutes}m${seconds}s`;
}
