import DefaultPage from './default-page';
import DefaultPageTransition from './default-page-transition';


export default (di) => {
	di
		.setType({type: DefaultPage, name: 'DefaultPage', parent: 'Page'})

		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
	;
};
