import VimeoApiLoader from './vimeo-api-loader';
import VimeoVideoPlayerDriver from './vimeo-video-player-driver';


export default (di) => {
	di
		.setType({type: VimeoApiLoader, name: 'VimeoApiLoader'})
		.set('vimeo/apiLoader', di.lazyNew('VimeoApiLoader'))
		.setType({type: VimeoVideoPlayerDriver, name: 'VimeoVideoPlayerDriver', parent: 'VideoPlayerDriver',  setters: {
				injectApiLoader: di.lazyGet('vimeo/apiLoader')
			}
		})

	;
};
