class HistoryApi {


	constructor({events}) {
		this.events = events;
		this.supported = !!(window.history && window.history.pushState);
		if (this.supported) {
			this.events.on(window, 'popstate', (event) => {
				events.trigger(document, 'history:popstate', {originalEvent: event});
	        });
		}
	}


	isSupported() {
	    return this.supported;
	}


	push(url, data, title) {
		window.history.pushState(data, title, url);
		if (title) {
			document.title = title;
		}
		return this;
	}


	replace(url, data, title) {
		window.history.replaceState(data, title, url);
		if (title) {
			document.title = title;
		}
		return this;
	}


	back() {
		window.history.back();
	}


	getState() {
		const state = window.history.state;
		if (state === null || state === undefined) {
			return {};
		}
		return Object.assign({}, state);
	}


	mergeState(state) {
		state = Object.assign(this.getState(), state);
		return this.replace(location.href, state);
	}

}


export default HistoryApi;
