import cookies from 'js-cookie';
import PageComponent from '../component/page-component';
import {ucFirst} from '../utils/string';


class Trackers extends PageComponent {

	constructor({
		root,
		element,
		trackersAttribute = 'trackers',
		trackersEmbedCodeAttribute = 'trackersEmbedCode',
		separatorAttribute = 'separator',
		cookieNameAttribute = 'cookieName',
		cookieDurationAttribute = 'cookieDuration',
		actionAttribute = 'action',
		groupAttribute = 'group',
		trackerAttribute = 'tracker',
		delayAttribute = 'delay',
		anchorAttribute = 'anchor',
		hiddenClass = 'hidden',
		expandedClass = 'expanded',
		confirmModeClass = 'confirmMode',
		trackersDetailsId = 'trackersDetails',
		contextName = 'trackers'
	}) {
		super({root: root, element: element});
		this.actionAttribute = actionAttribute;
		this.trackersAttribute = trackersAttribute;
		this.trackersEmbedCodeAttribute = trackersEmbedCodeAttribute;
		this.separatorAttribute = separatorAttribute;
		this.cookieNameAttribute = cookieNameAttribute;
		this.cookieDurationAttribute = cookieDurationAttribute;
		this.groupAttribute = groupAttribute;
		this.trackerAttribute = trackerAttribute;
		this.delayAttribute = delayAttribute;
		this.anchorAttribute = anchorAttribute;
		this.hiddenClass = hiddenClass;
		this.expandedClass = expandedClass;
		this.confirmModeClass = confirmModeClass;
		this.trackersDetailsId = trackersDetailsId;
		this.contextName = contextName;
		this.trackers = new Map();
		this.confirmMode = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.cookieName = data[this.cookieNameAttribute];
		this.cookieDuration = data[this.cookieDurationAttribute];
		this.separator = data[this.separatorAttribute];
		this.trackersEmbedCode = data[this.trackersEmbedCodeAttribute];
		const delay = data[this.delayAttribute]; // seconds
		const anchor = data[this.anchorAttribute];
		const trackers = data[this.trackersAttribute];
		this.initData(trackers);
		this.details = this.queryComponent(this.dataSelector('id', this.trackersDetailsId));
		this.listeners.actions = this.events.on(this.element, this.dataSelector(this.actionAttribute), 'click', this.onAction.bind(this));
		this.listeners.inputChange = this.events.on(this.element, 'input[type="radio"]', 'change', this.onInputChange.bind(this));
		this.listeners.reopen = this.events.on(document, 'a[href="#' + anchor + '"]', 'click', this.onReopenClick.bind(this), {capture: true});
		const cookieData = cookies.getJSON(this.cookieName);
		if (cookieData) {
			this.applyTrackers(cookieData);
			this.confirmMode = true;
		} else {
			setTimeout(() => {
				this.show();
			}, delay * 1000);
		}
	}


	onReopenClick(event) {
		event.preventDefault();
		this.show();
	}


	onAction(event, target) {
		const actionName = this.dataAttr(target).get(this.actionAttribute);
		const method = 'on' + ucFirst(actionName);
		this[method](event, target);
	}


	onToggleDetails(event) {
		if (this.contexts.getCurrentContext().getName() !== this.contextName) {
			this.contexts.push(this.contextName);
		}
		this.classList().toggle(this.expandedClass, this.details.isCollapsed());
		this.details.toggle();
	}


	onInputChange(event, target) {
		const data = this.dataAttr(target).getAll();
		const groupName = data[this.groupAttribute];
		const trackerName = (this.trackerAttribute in data ? data[this.trackerAttribute] : null);
		if (trackerName !== null) {
			this.updateTracker(groupName, trackerName, target.value);
		} else {
			this.updateGroup(groupName, target.value);
		}
	}


	onSave(event) {
		this.finalize();
	}


	onOk(event) {
		this.finalize();
	}


	onRejectAll(event) {
		this.updateAll(0);
	}


	onAcceptAll(event) {
		this.updateAll(1);
	}


	initData(trackers) {
		this.trackers.clear();
		for (const groupName in trackers) {
			if (trackers.hasOwnProperty(groupName)) {
				const group = {
					trackers: new Map(),
					selected: 0,
					input: [
						this.element.querySelector('#' + groupName + this.separator + '0'),
						this.element.querySelector('#' + groupName + this.separator + '1'),
					]
				};
				this.trackers.set(groupName, group);
				for (const trackerName in trackers[groupName]) {
					if (trackers[groupName].hasOwnProperty(trackerName)) {
						if (!trackers[groupName][trackerName].required) {
							const tracker = Object.assign({
								input: [
									this.element.querySelector('#' + groupName + this.separator + trackerName + this.separator + '0'),
									this.element.querySelector('#' + groupName + this.separator + trackerName + this.separator + '1'),
								]
							}, trackers[groupName][trackerName]);
							tracker.input[0].checked = (tracker.value === 0);
							tracker.input[1].checked = (tracker.value === 1);
							group.trackers.set(trackerName, tracker);
							group.selected += tracker.value;
						}
					}
				}
				if (group.trackers.size > 0) {
					group.input[0].checked = (group.selected === 0);
					group.input[1].checked = (group.selected === group.trackers.size);
				}
			}
		}
	}


	updateAll(value) {
		for (const groupName of this.trackers.keys()) {
			this.updateGroup(groupName, value);
		}
		this.finalize();
	}


	updateGroup(groupName, value) {
		value = parseInt(value, 10);
		const group = this.trackers.get(groupName);
		group.selected = (value  ? group.trackers.size : 0);
		for (const tracker of group.trackers.values()) {
			tracker.value = value;
			tracker.input[0].checked = (value === 0);
			tracker.input[1].checked = (value === 1);
		}
	}


	updateTracker(groupName, trackerName, value) {
		value = parseInt(value, 10);
		const group = this.trackers.get(groupName);
		const tracker = group.trackers.get(trackerName);
		if (value !== tracker.value) {
			tracker.value = value;
			group.selected += (value === 1 ? 1 : -1);
			group.input[0].checked = (group.selected === 0);
			group.input[1].checked = (group.selected === group.trackers.size);
		}
	}


	finalize() {
		const previousData = cookies.getJSON(this.cookieName);
		const data = this.extractData();
		this.save(data);
		this.hide();
		if (this.confirmMode) {
			if (previousData && JSON.stringify(previousData) !== JSON.stringify(data)) {
				window.location.reload();
			}
		} else {
			this.applyTrackers(data);
		}
	}


	extractData() {
		const data = {};
		for (const [groupName, group] of this.trackers) {
			data[groupName] = {};
			for (const [trackerName, tracker] of group.trackers) {
				data[groupName][trackerName] = {
					type: tracker.type,
					value: tracker.value
				};
			}
		}
		return data;
	}


	save(data) {
		cookies.set(this.cookieName, data, {expires: this.cookieDuration});
	}


	applyTrackers(data) {
		for (const groupName in data) {
			if (data.hasOwnProperty(groupName)) {
				const group = data[groupName];
				for (const trackerName in group) {
					if (group.hasOwnProperty(trackerName)) {
						const tracker = group[trackerName];
						if (tracker.value === 1 && tracker.type in this.trackersEmbedCode && this.trackersEmbedCode[tracker.type] !== null) {
							const div = document.createElement('div');
							div.innerHTML = this.trackersEmbedCode[tracker.type];
							for (const node of div.childNodes) {
								// if you append directly the script it does not execute
								// it needs to be recreated
								if (node instanceof HTMLScriptElement) {
									const script = document.createElement('script');
									for (const attribute of node.attributes) {
										script.setAttribute(attribute.name, attribute.value);
									}
									script.textContent = node.textContent;
									document.body.appendChild(script);
								} else {
									document.body.appendChild(node);
								}
							}
						}
					}
				}
			}
		}
	}


	show() {
		if (this.confirmMode)  {
			this.classList().add(this.confirmModeClass);
		}
		this.classList().remove(this.hiddenClass);
	}


	hide() {
		if (this.contexts.getCurrentContext().getName() === this.contextName) {
			this.contexts.pop(this.contextName).then(() => {
				this.details.collapse();
				this.classList().add(this.hiddenClass);
				this.classList().remove(this.expandedClass);
			});
		} else {
			this.classList().add(this.hiddenClass);
		}
	}

}


export default Trackers;
