import Container from './common/di/container';
import config from './app/config/config';

const container = new Container();
container.setValue('dom/dataAttrPrefix', 'xxx-');
container.setValue('dom/classPrefix', 'xxx-js-');
container.setValue('dom/baseClassPrefix', 'xxx-');
config(container);

const app = container.get('app');

app.init().then(() => app.execute());
